<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-button @click="generate()" class="mr-3">Genereer</b-button>
        <b-button @click="refresh()">Ververs</b-button>

        <b-table-simple small striped class="mt-3">
          <b-tbody>
            <tr v-for="(st, index) in statements" :key="index">
              <td>{{ st }}</td>
              <td><b-button @click="download(st)">Download</b-button></td>
              
            </tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'RoyaltyAnalytics',
    components: {
    },
    data () {
      return {
        filter: {
          apFrom: '',
          apTo: '',
        },
        statements: [],
      }
    },
    props: {
      inputParams: Object
    },
    methods: {
      refresh () {
        this.$http
          .get('portal/get-statements')
          .then(response => {
            this.statements = response.data
          })
      },
      generate () {
        this.$http
          .get('portal/generate-statements') 
      },
      download (st) {
        this.$http
          .get('portal/download-statement/' + st, { responseType: 'blob' })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', st)
            // document.body.appendChild(link)
            link.click()
          })
      }
    },
    computed: {
    },
    mounted () {
      this.refresh()
      this.$http
        .get('analytics/accounting-periods')
        .then(response => {
          const result = response.data
          this.apOptions = []
          result.forEach(item => {
            this.apOptions.push({
              value: item.id,
              text: item.description
            })
          })
          
          this.filter.apFrom = this.apOptions[this.apOptions.length - 1].value
          this.filter.apTo = this.filter.apFrom
        })
    }
  }
</script>

<style scoped>
  .btn { white-space: nowrap; }

  .custom-select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
</style>
